import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="w-full max-w-4xl px-3 pt-3 mx-auto md:pt-6 md:px-6 text-center font-black dark:text-gray-200">
        <h1 className="text-5xl">404</h1>
        <h2 className="text-2xl font-bold inline-block">
          Page Not Found
        </h2>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
